<template>
  <div class="row">
    <div class="col-md-6">
        <!-- <TextInput
          v-model="agency.street_number"
          name="street_number"
          rules="required"
          label="Street Number"
        /> -->
        <label>Street Number</label>
        <bootstrap-dropdown name="street_number" :options="options" ::value="agency.street_number" @select="addressSelect" />
      </div>
      <div class="col-md-6">
        <TextInput
          v-model="agency.street_name"
          name="street_name"
          rules="required"
          label="Street Name"
        />
      </div>
      <div class="col-md-12">
        <TextInput
          v-model="agency.address_line_2"
          name="address_line_2"
          label="Address Line 2"
        />
      </div>
      <div class="col-md-6">
        <TextInput
          v-model="agency.city"
          name="city"
          rules="required"
          label="City"
        />
      </div>
      <div class="col-md-6">
        <TextInput
          v-model="agency.state"
          name="state"
          rules="required"
          label="State/Province"
        />
      </div>
      <div class="col-md-6">
        <TextInput
          v-model="agency.zip_code"
          name="zip_code"
          rules="required"
          label="ZIP/Postal code"
        />
      </div>
      <div class="col-md-6">
        <TextInput
          v-model="agency.country"
          name="country"
          rules="required"
          label="Country"
        />
      </div>
  </div>   
</template>

<script>
import { BootstrapDropdown } from 'vue-custom-google-autocomplete'

export default {
  components: {
    BootstrapDropdown
  },

  props: {
    agency: {
      required: false,
      type: Object,
      default() {
        return {}
      },
    },
  },

  data() {
    return {
      options: {
        apiKey: process.env.VUE_APP_PLACE_API_KEY,
        deepSearch: true,
        cors: false,
        focus: false,
        params: {
          language: 'en'
        },
      },
      mapAttributes: {
        street_number: 'street_number',
        route: 'street_name',
        sublocality_level_1: 'address_line_2',
        locality: 'city',
        administrative_area_level_2: 'city',
        administrative_area_level_1: 'state',
        country: 'country',
        postal_code: 'zip_code',
      }
    }
  },

  methods: {
    addressSelect(event) {
      if (event.addressComponents) {
        for (let section of event.addressComponents) {
          for (let attr in this.mapAttributes) {
            if (section.types.includes(attr)) {
              this.agency[this.mapAttributes[attr]] = section.longName
            }
          } 
        }

        this.$emit('update:agency', {...this.value, ...this.agency})
      }
    }
  }
}
</script>